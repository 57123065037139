// You can customize the template with the help of this file

//Template config options
// const themeConfig = {
//   app: {
//     appName: 'Modigi',
//     appLogoImage: require('@src/assets/images/logo/logo.svg').default,
//     vendorName: 'Aksimaya',
//     vendorWww: 'https://www.aksimaya.co.id'
//   },
//   layout: {
//     isRTL: false,
//     skin: 'light', // light, dark, bordered, semi-dark
//     routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
//     type: 'vertical', // vertical, horizontal
//     contentWidth: 'boxed', // full, boxed
//     menu: {
//       isHidden: false,
//       isCollapsed: false
//     },
//     navbar: {
//       // ? For horizontal menu, navbar type will work for navMenu type
//       type: 'sticky', // static , sticky , floating, hidden
//       backgroundColor: 'white' // BS color options [primary, success, etc]
//     },
//     footer: {
//       type: 'static' // static, sticky, hidden
//     },
//     customizer: false,
//     scrollTop: true // Enable scroll to top button
//   }
// }

// export default themeConfig

// ga jalan
// const func = async () => {
//   // const config = require('./modigi').default
//   console.log('process.env.REACT_APP_CONFIGNAME: ', process.env.REACT_APP_CONFIGNAME)
//   const config = (await import(process.env.REACT_APP_CONFIGNAME)).default
//   console.log('config: ', config)

//   return config
// }

// export default func()

// const func = () => {
//   const config = {
//     modigi: require('./modigi').default,
//     testaja: require('./modigi').default
//   }

//   return config[process.env.REACT_APP_CONFIGNAME]
// }

// export default func()

const config = {
    modigi: require('./modigi').default,
    testaja: require('./modigi').default
}

export default config[process.env.REACT_APP_CONFIGNAME]

