// ** React Imports
import { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Reactstrap Imports
import { NavItem, NavLink, Button } from 'reactstrap'

// ** Third Party Components
import { Sun, Moon } from 'react-feather'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'

const ThemeNavbar = props => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props
  const stateAuth = useSelector(state => state.auth)
  // console.log('stateAuth: ', stateAuth)
  useEffect(() => {

  }, [stateAuth])  

  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }  

  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>

    {stateAuth.userData && !stateAuth.userData.id ? (
      <ul className='nav navbar-nav align-items-center ms-auto'>
        <NavItem>
          <NavLink className='nav-link-style'>
            <ThemeToggler />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='/login' className='nav-link-style'>
            <Button color='primary' outline>LOGIN</Button>
          </NavLink>
        </NavItem>        
      </ul> 
    ) : null}     
      {stateAuth.userData && stateAuth.userData.id ? <NavbarUser skin={skin} setSkin={setSkin} /> : null}  
    </Fragment>
  )
}

export default ThemeNavbar
