// ** Navigation imports
import apps from './apps'
import pages from './pages'
import forms from './forms'
import tables from './tables'
import others from './others'
import charts from './charts'
import dashboards from './dashboards'
import uiElements from './ui-elements'

import user from '@modules/user/menu'
import dealer from './dealer'

import { Home } from 'react-feather'

const businesMenu =  [
    {
        id: 'business-dashboard',
        title: 'Dashboard',
        icon: <Home size={12} />,
        navLink: '/business/dashboard'
    }    
]

// import themeConfig from '@configs/themeConfig'

// ** Merge & Export
export default [
    ...businesMenu, ...user, ...dealer
    // , ...apps, ...dashboards, ...pages, ...uiElements, ...forms, ...tables, ...charts, ...others
]

// import themeConfig from '@configs/themeConfig'

// export default [...themeConfig.navigation]