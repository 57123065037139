import mock from '../mock'

const data = {
  pricing: {
    basicPlan: {
      title: 'Basic',
      img: require('@src/assets/images/illustration/Pot1.svg').default,
      subtitle: 'A simple start for everyone',
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 0,
        totalAnnual: 0
      },
      planBenefits: [
        '100 responses a month',
        'Unlimited forms and surveys',
        'Unlimited fields',
        'Basic form creation tools',
        'Up to 2 subdomains'
      ],
      popular: false
    },
    standardPlan: {
      title: 'Standard',
      img: require('@src/assets/images/illustration/Pot2.svg').default,
      subtitle: 'For small to medium businesses',
      monthlyPrice: 49,
      yearlyPlan: {
        perMonth: 40,
        totalAnnual: 480
      },
      planBenefits: [
        'Unlimited responses',
        'Unlimited forms and surveys',
        'Instagram profile page',
        'Google Docs integration',
        'Custom “Thank you” page'
      ],
      popular: true
    },
    enterprisePlan: {
      title: 'Enterprise',
      img: require('@src/assets/images/illustration/Pot3.svg').default,
      subtitle: 'Solution for big organizations',
      monthlyPrice: 99,
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960
      },
      planBenefits: [
        'PayPal payments',
        'Logic Jumps',
        'File upload with 5GB storage',
        'Custom domain support',
        'Stripe integration'
      ],
      popular: false
    },
    qandA: [
      {
        question: 'Bagaimanakah cara menjual mobil di MODIGI?',
        ans:
          `MODIGI Indonesia menawarkan cara baru menjual mobil sehingga anda dapat menerima harga terbaik untuk setiap penjualan mobil anda. Tidak perlu lagi anda harus mengeluarkan biaya untuk mengiklankan mobil anda dan juga menghemat waktu karena anda tidak perlu lagi mengunjungi setiap dealer mobil untuk mendapatkan harga yang anda inginkan.

          Langkah - langkah menjual mobil di MODIGI :

          - Masukkan data-data mengenai mobil anda dan data pribadi anda.
          - Tim MODIGI akan segera menghubungi anda untuk melakukan verifikasi ulang data yang telah anda masukkan.
          - Setelah verifikasi data selesai, tim kami akan menginfokan harga terbaik mobil anda, apabila anda setuju, tim kami akan segera membantu untuk menjadwalkan survey di lokasi anda ataupun lokasi lain yang memudahkan anda. 
          - Saat survey, tim inspektor kami akan melakukan pengecekan mobil anda, dan juga mengambil foto-foto untuk kami upload di webite kami sehingga dealer-dealer terpercaya kami dapat melihat  mobil anda.
          - Setelah Tim MODIGI mendapatkan harga, Tim MODIGI akan segera menghubungi anda untuk membantu proses penjualan mobil anda.`
      },
      {
        question: 'Mengapa menggunakan MODIGI untuk menjual mobil saya?',
        ans:
          `MODIGI Indonesia adalah cara tercepat dan termudah untuk menjual mobil anda! Anda bisa mendapatkan nilai jual mobil terbaik yang transparan dari tim survey kami sehingga anda dapat menghemat banyak waktu dan biaya.

          Keuntungan menggunakan MODIGI Indonesia 
          
          - Mendapatkan penawaran harga paling baik
          
          - Jual Mobil anda dalam waktu 1 hari!
          
          - 100% Gratis`
      },
      {
        question: 'Apakah itu survey?',
        ans:
          `Survey adalah proses pengecekan kondisi mobil anda dimana inspektor berpengalaman kami akan melakukan beberapa titik poin inspeksi pada mobil dan juga melakukan test-drive mobil anda untuk mengetahui kondisi mobil anda lebih lanjut. Survey mobil anda tidak akan memakan waktu lebih dari 45-60 menit.`
      },
      {
        question: 'Bagaimanakah cara membuat jadwal survey?',
        ans:
          `Setelah memasukkan data-data mobil dan data-data pribadi anda, anda akan dihubungi oleh tim kami untuk pembuatan jadwal survey pada hari dan waktu yang memudahkan anda. Survey akan dilakukan di tempat kediaman anda atau tempat lainnya yang anda tentukan (sementara ini hanya untuk wilayah Bogor).`
      }
    ]
  }
}

mock.onGet('/pricing/data').reply(() => [200, data.pricing])
