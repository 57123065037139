// ** React Imports
import { Link } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'

import * as Icon from 'react-feather'
import themeConfig from '@configs/themeConfig'
import { useSelector } from 'react-redux'

// ** Reactstrap Imports
import {
  NavItem,
  NavLink
} from 'reactstrap'

const NavbarBookmarks = props => {
  const { setMenuVisibility } = props

  // add by yogie untuk perbaikan bugs klo layout menuHidden nya true tetep ada button hamburger nya
  const layoutStore = useSelector(state => state.layout)

  useEffect(() => {
    console.log('layoutStore: ', layoutStore)
  }, [layoutStore])

  return (
    <Fragment>

      <Link className='brand-logo' to='/'>
          <themeConfig.app.logoSvg size={24} />
      </Link>

      <ul className='navbar-nav d-xl-none'>
        {themeConfig.app.accountType ? (
        <NavItem className='mobile-menu me-auto'>
          <Link to='/'>
            <h2 className='brand-text text-primary mb-0 ps-1 d-xl-none'>{themeConfig.app.accountType.toUpperCase()}</h2>
          </Link>      
        </NavItem>
        ) : (
          <NavItem className='mobile-menu me-auto'>
          <Link to='/'>
            <h2 className='brand-text text-primary mb-0 ps-1 d-xl-none'>{themeConfig.app.appName}</h2>
          </Link>      
        </NavItem>          
        ) }

        {layoutStore.menuHidden ? null : (
        <NavItem className='mobile-menu me-auto'>
        <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
          <Icon.Menu className='ficon' />
        </NavLink>
      </NavItem>
        )}

      </ul>

      <Link to='/'>
        <h2 className='brand-text text-primary mb-0 ps-1 d-none d-xl-block'>{themeConfig.app.appName}</h2>
      </Link>
    </Fragment>
  )
}

export default NavbarBookmarks
