// ** Icons Import
import { List, Circle } from 'react-feather'

export default {
    id: 'vehicle.inspections',
    title: 'Inspeksi',
    icon: <List size={20} />,
    children: [
      {
        id: 'vehicle.inspection.list',
        title: 'List',
        icon: <Circle size={12} />,
        deepNavlLink: ['/app/vehicle/inspection/view', '/app/vehicle/inspection/update'],
        navLink: '/app/vehicle/inspection/list'
      },
      {
        id: 'vehicle.inspection.create',
        title: 'Create',
        icon: <Circle size={12} />,
        navLink: '/app/vehicle/inspection/create'
      }      
      // ,
      // {
      //   id: 'view',
      //   title: 'View',
      //   icon: <Circle size={12} />,
      //   navLink: '/app/vehicle/inspection/view'
      // }
    ]
}
