// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import requestApi from '@services/requestApi'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  console.log('store appUsers/getAllData loaded')

  // const paramss = {
  //   token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoxLCJzb3VyY2UiOiIiLCJpYXQiOjE2Mzc4NTY5MzMsImV4cCI6MTYzNzk0MzMzM30.xNj8niaAiJwSlaO6HN0-PGoIZLyI_VLpf9z3_8GcwiY'
  // }

  
  // const response = await axios.get('https://api.dehills.id/users', { params: paramss })

  const url = '/users'
  const response = await requestApi({ url })
  console.log('appUsers/getAllData response: ', response)

  return response.data
})

// testing awal yg pake axios lgsung = gak pake requestApi
// export const getData = createAsyncThunk('appUsers/getData', async params => {
//   console.log('params: ', params)
//   const paramss = {
//     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoxLCJzb3VyY2UiOiIiLCJpYXQiOjE2Mzc4NTY5MzMsImV4cCI6MTYzNzk0MzMzM30.xNj8niaAiJwSlaO6HN0-PGoIZLyI_VLpf9z3_8GcwiY',
//     sort: `${params.sortColumn} ${params.sort}`,
//     limit: params.perPage,
//     skip: (params.page - 1) * params.perPage
//   }

//   if (params.status) paramss.status = params.status
//   console.log('paramss: ', paramss)
//   const response = await axios.get('https://api.dehills.id/users', { params: paramss })

//   console.log('getData response: ', response)

//   for (const row of response.data.data) {
//     // console.log('row: ', row)
//     row.fullName = `${row.firstName} ${row.lastName}`
//     row.avatar = row.profilePicture
//     row.status = row.status.toLowerCase()
//   }

//   return {
//     params,
//     data: response.data.data,
//     totalPages: response.data.metadata.numrows
//   }
// })

export const getData = createAsyncThunk('appUsers/getData', async params => {
  console.log('store appUsers/getData loaded')

  console.log('params: ', params)

  const paramss = {
    sort: `${params.sortColumn} ${params.sort}`,
    limit: params.perPage,
    skip: (params.page - 1) * params.perPage
  }

  if (params.status) paramss.status = params.status
  console.log('paramss: ', paramss)

  const url = `/users`
  const response = await requestApi({ url, params: paramss })  

  for (const row of response.data) {
    row.fullName = `${row.firstName} ${row.lastName}`
    row.avatar = row.profilePicture
    row.status = row.status.toLowerCase()
  }

  return {
    params,
    data: response.data,
    totalPages: response.metadata.numrows
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  console.log('store appUsers/getUser loaded')

  const url = `/users`
  const response = await requestApi({ url, params: { id } })  
  console.log('getUser response: ', response)
  const userData = response.data.length ? response.data[0] : {}

  if (userData.id) userData.fullName = `${userData.firstName} ${userData.lastName}`

  return userData
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })

      .addCase(getData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })

      .addCase(getUser.pending, (state, action) => {
        state.loading = true
      })      
      .addCase(getUser.fulfilled, (state, action) => {
        console.log('getUser.fulfilled action.payload: ', action.payload)
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
