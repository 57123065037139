import { User, Circle } from 'react-feather'

export default [
  {
    id: 'user',
    title: 'User',
    icon: <User size={20} />,
    children: [
      {
        id: 'user.list',
        title: 'List',
        icon: <Circle size={12} />,
        deepNavlLink: ['/app/user/view'],
        navLink: '/app/user/list'
        // ,
        // children: [
        //   {
        //     id: 'view',
        //     title: 'View',
        //     icon: <Circle size={12} />,
        //     navLink: '/app/user/view'
        //   }          
        // ]
      }
    ]
  }
]