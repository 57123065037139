// import modAuthConfig from '@modules/auth/config'

// ** Auth Endpoints
const jwtConfig = {
    loginEndpoint: '/jwt/login',
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
  
    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',
  
    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
}

// const auth = { ...jwtConfig, ...modAuthConfig }
//  console.log('auth: ', auth)

// export default auth
export default jwtConfig

// 311532184126-5h4sgopr2pirssbkvo33oknmn3pon1bp.apps.googleusercontent.com