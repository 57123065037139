const __DEV__ = true

import colors from './colors.json'

import layout from './layout'
import logoSvg from './logo-svg'

import navbar from './navbar'
import navigationHorizontal from './navigation/horizontal'
import navigationVertical from './navigation/vertical'

import jwtConfig from './auth'

const appConfig = window.appConfig
console.log('appConfig: ', appConfig)
const auth = {...jwtConfig, ...appConfig.auth}
console.log('auth: ', auth)

export default {
    layout,
    colors,
    navigation: {
        horizontal: navigationHorizontal,
        vertical: navigationVertical
    },
    components: {
        navbar
    },
    app: {
        appName: 'Modigi',
        // accountType: 'Dealer',
        appLogoImage: require('@src/assets/images/logo/logo.svg').default,
        logoSvg 
    },
    auth,
    vendor: {
        name: 'Aksimaya',
        website: 'https://www.aksimaya.co.id',
        email: 'hello@aksimaya.co.id'
    },        
    scheme: 'dark',
    api: {
        url: `https://api.dehills.id${__DEV__ ? '/dev' : ''}`,
        key: null,
        secret: null
    },
    static: { url: 'https://static.dehills.id' },
    notification: {
        enable: true,
        channel: {
            channelId: 'dehills-notification',
            channelName: 'DeHills Notification',
            channelDescription: 'DeHills notification channel',
            soundName: 'default'
        }
    },
    socket: {
        enable: true,
        url: 'https://api.dehills.id',
        path: __DEV__ ? '/dev/socket.io' : ''
    },
    codePush: {
        enable: true,
        options: {
            checkFrequency: 1, // ON_APP_RESUME
            // deploymentKey: 'FJxnAE8WU2_6LonlpTWX1t55n23mQrp5cHLp7', // Android Staging (default)
            // deploymentKey: 'u4PNXA1rKaRZeSHdydCuWhR_K0z7LDUnCKA6n', // Android Production
            // deploymentKey: 'lBc3DYueiBCqL7f5WhDMTFbQi-VyoTEFeLE4f', // iOS Staging (default)
            // deploymentKey: 'D1B-eugDkxDeQS6uglgxQecPmPECPjXEqNISU', // iOS Production
            installMode: 2, // ON_NEXT_RESUME
            mandatoryInstallMode: 1, // ON_NEXT_RESTART
            minimumBackgroundDuration: 60 * 5 // 5 minutes
        }
    },
    adMob: {
        enable: true,
        unitIDs: {
            appOpen: 'ca-app-pub-9387323949752538/7145779112'
            // banner: '',
            // interstitial: '',
            // rewarded: ''
        }
    }
}
