// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import requestApi from '@services/requestApi'

export const getAllData = createAsyncThunk('vehicleInspection/getAllData', async () => {
  console.log('store vehicleInspection/getAllData loaded')

  const url = '/vehicle/inspections'
  const response = await requestApi({ url })
  console.log('vehicleInspection/getAllData response: ', response)

  return response.data
})

export const getData = createAsyncThunk('vehicleInspection/getData', async params => {
  console.log('store vehicleInspection/getData loaded')

  console.log('vehicleInspection/getData params: ', params)

  params.collect = 'user'
  params.sort = `${params.sortColumn} ${params.sort}`
  params.limit = params.perPage
  params.skip = (params.page - 1) * params.perPage

  console.log('params.locationType.length: ', params.locationType.length)
  if (!params.status.length) delete params.status
  if (!params.locationType.length) delete params.locationType

  // bersih bersih
  delete params.perPage
  delete params.page
  delete params.sortColumn
  delete params.q
  delete params.currentPlan

  // const paramss = {
  //   collect: 'user',
  //   sort: `${params.sortColumn} ${params.sort}`,
  //   limit: params.perPage,
  //   skip: (params.page - 1) * params.perPage
  // }

  // if (params.status) paramss.status = params.status
  // console.log('paramss: ', paramss)

  console.log('vehicleInspection/getData params 2: ', params)
  const url = `/vehicle/inspections`
  const response = await requestApi({ url, params })  

  for (const row of response.data) {
    if (row.user && row.user.id) row.user.fullName = `${row.user.firstName} ${row.user.lastName}`
    // row.avatar = row.profilePicture
    // row.status = row.status.toLowerCase()
  }

  console.log('response==: ', response)

  return {
    params,
    data: response.data,
    totalPages: response.metadata.numrows
  }
})

export const getVehicleInspection = createAsyncThunk('vehicleInspection/getVehicleInspection', async id => {
  console.log('store vehicleInspection/getVehicleInspection loaded')

  const url = `/users`
  const response = await requestApi({ url, params: { id } })  
  console.log('getUser response: ', response)
  const userData = response.data.length ? response.data[0] : {}

  if (userData.id) userData.fullName = `${userData.firstName} ${userData.lastName}`

  return userData
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'vehicleInspection',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })

      .addCase(getData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })

      .addCase(getVehicleInspection.pending, (state, action) => {
        state.loading = true
      })      
      .addCase(getVehicleInspection.fulfilled, (state, action) => {
        console.log('getUser.fulfilled action.payload: ', action.payload)
        state.selected = action.payload
      })
  }
})

export default appUsersSlice.reducer
