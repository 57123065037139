import axios from 'axios'
import qs from 'qs'

// import { getItem } from './storage'

const CancelToken = axios.CancelToken
export { CancelToken }

const requestApi = axios.create()
const authToken = ''

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
// const useJwtConfig = useJwt.jwtConfig
// const useJwtToken = useJwt

requestApi.defaults.headers.common['Authorization'] = authToken

requestApi.interceptors.request.use(async config => {
    const { url, key } = require('../configs/themeConfig').default.api
    // const token = await getItem('@token:key')

    // console.log('requestApi useJwt: ', useJwt.getToken())
    const token = JSON.parse(await useJwt.getToken())

    config.baseURL = url
    switch (config.method) {
        case 'get':
        default:
            if (config.params === undefined) config.params = {}
            if (token !== null) config.params.token = token
            if (key) config.params.appKey = key
            break
        case 'post':
            if (config.data === undefined) config.data = {}
            if (token !== null) config.data.token = token
            if (key) config.data.appKey = key
            config.data = qs.stringify(config.data)
            break
    }
    return config
})

requestApi.interceptors.response.use(
    response => response.data,
    error => {
        let message = null
        console.log('requestApi error.response: ', error.response)
        if (error.response) {
            if (error.response.data && typeof error.response.data.data === 'string') {
                message = error.response.data.data
            } else {
                message = error.response.statusText
            }

        } else message = error.message
        return Promise.reject(message || error)
    }
)

export default requestApi
